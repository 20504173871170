<template>
  <b-modal
    id="lesson-modal"
    ref="lesson-modal"
    :title="'Tạo bài học mới'"
    @show="openStaffPopup"
    @hidden="resetModal"
    @ok="submit"
  >
    <b-container fluid class="p-0">
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-row>
          <b-col style="min-height: 170px" class="mt-4">
            <div class="picture-input-container">
              <picture-input
                ref="pictureInput"
                width="228"
                height="128"
                margin="16"
                accept="image/jpeg,image/png"
                size="16"
                buttonClass="btn btn-success"
                :crop="false"
                :removable="true"
                :customStrings="customStrings"
              >
              </picture-input>
              <div class="mt-3 text-center">
                <span>Hình ảnh bài học tỷ lệ 16:9</span>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <basic-select
              label="Thuộc khoá học"
              :options="[]"
              placeholder="MD.001 - Tổng quan về bệnh đái tháo đường"
              name="wards"
              :value.sync="form.emotionCategoryId"
              :solid="false"
              :allowEmpty="false"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <basic-input
              :required="true"
              label="Tên bài học "
              name="vietnameseName"
              v-validate="'required'"
              :value.sync="form.vietnameseName"
              placeholder="Nhập tên bài học"
            ></basic-input>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <basic-text-area
              label="Nội dung giới thiệu"
              placeholder="Nhập nội dung giới thiệu bài học"
              name="note"
              :value.sync="form.description"
            ></basic-text-area>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-checkbox size="lg">
              <p>
                Đã soạn thảo hoàn tất bài học
                <span style="color: #828598">
                  (App chỉ hiển thị các bài học đã hoàn tất soạn thảo)</span
                >
              </p>
            </b-checkbox>
          </b-col>
        </b-row>
      </form>
    </b-container>
    <template #modal-footer>
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button
          class="btn btn-success ml-2"
          href="#"
          @click="submit"
          tabindex="0"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Lưu
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import PictureInput from 'vue-picture-input';
export default {
  name: 'LessonModal',
  components: {
    PictureInput,
  },
  data() {
    return {
      form: {},
      rendered: false,
      validationState: {},
      error: {},
      filter: {},
      groupFoods: [
        { name: 'Banh canh', id: 1 },
        { name: 'Hu tiu', id: 2 },
        { name: 'Com', id: 3 },
        { name: 'Banh tran tron', id: 4 },
      ],
    };
  },
  computed: {
    customStrings: {
      get() {
        return {
          drag: `<div style="
            height: 28px;
            width: 28px;
            background-color: #fff;
            box-shadow: -1px 2px 10px rgba(0, 0, 0, 0.2);
            margin: auto;"
            class="d-flex align-items-center justify-content-center rounded-circle">
            <span>
            <img src="/media/svg/icons/Neolex/Basic/edit-2.svg" alt="Edit Icon" class="svg-icon" width="14px" height="14px" />
            </span>
            </div>`,
          change: 'Đổi hình ảnh',
          remove: 'Xóa hình ảnh',
        };
      },
    },
  },
  methods: {
    openStaffPopup() {
      this.rendered = true;
      setTimeout(() => {
        this.$refs.pictureInput.onResize();
      }, 500);
    },
    submit() {},
    resetModal() {},
  },
};
</script>

<style lang="scss">
#lesson-modal {
  .modal-dialog {
    max-width: 550px;
  }
}
.label-required:after {
  content: '*';
  color: red;
}
</style>
<style lang="scss" scoped>
.picture-input-container {
  .picture-input {
    .preview-container {
      background: #ffffff;
      border: 3px solid #ffffff;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 6px;

      .picture-preview {
        background-color: #f3f6f9 !important;
      }

      .picture-inner {
        border: none;
      }
    }
  }
}
</style>
